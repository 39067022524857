export interface AnalysesQuota {
  CBCT?: number;
  CEPH?: number;
  OPG?: number;
}

export interface UserInterface {
  uuid?: string;
  first_name: string;
  last_name: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
  status?: number;
  role_id?: number;
  ai_analyses_executed?: AnalysesQuota;
  ai_analyses_limit_data?: AnalysesQuota;
  parent?: UserInterface;
  permissions_set?: UserPermissionsType;
}

export interface UserUpdateStatusInterface {
  uuid: string;
  status: 0 | 1;
}

export interface UserUpdateParentInterface {
  uuid: string;
  parent_uuid: string | null;
}

export interface UserUpdatePermissionsInterface {
  uuid: string;
  value: UserPermissionsType;
}

export interface UserUpdateAnalysesLimitInterface {
  uuid: string;
  type: XRAY_TYPES;
  limit: number;
}

export type UserPermissionsType = "all" | "view_only" | "all_except_ai" | "custom" | undefined;
export type XrayTypesType = "CBCT" | "CEPH" | "OPG";

export enum USER_PERMISSIONS {
  all = "Unrestricted",
  view_only = "Viewer",
  all_except_ai = "Manager",
  custom = "Custom",
}

export enum XRAY_TYPES {
  OTHER,
  CEPH,
  OPG,
  BW,
  PA,
  CBCT,
}
