import Modal, { Styles } from "react-modal";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-icon.svg";

Modal.setAppElement("#root");

export const CommonModal = ({
  isOpen,
  onRequestClose,
  style,
  className,
  overlayClassName,
  children,
}: {
  isOpen: boolean;
  onRequestClose: any;
  style?: Styles;
  className: string;
  overlayClassName: string;
  children: JSX.Element;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={style}
      className={className}
      overlayClassName={overlayClassName}
    >
      <div className="flex flex-col justify-center items-center min-w-[240px] max-w-sm">
        <button className="absolute top-3 right-3" onClick={onRequestClose}>
          <CancelIcon className="fill-white/30 hover:fill-white transition-colors" />
        </button>
        {children}
      </div>
    </Modal>
  );
};
