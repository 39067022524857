import {
  UserUpdateAnalysesLimitInterface,
  UserUpdateParentInterface,
  UserUpdatePermissionsInterface,
  UserUpdateStatusInterface,
} from "interfaces/user";
import { request } from "./http";

const UsersService = {
  get: (keyword?: string, sortBy?: string, order?: string, page?: number, perPage?: string) =>
    request({
      method: "get",
      url: `users?keyword=${keyword || ""}&sort_by=${sortBy || ""}&order=${order || ""}&page=${
        page || "1"
      }&per_page=${perPage || "25"}`,
    }),
  updateStatus: (data: UserUpdateStatusInterface) =>
    request({ method: "put", url: `users/${data.uuid}/status`, data }),
  updateParent: (data: UserUpdateParentInterface) => {
    return request({
      method: "put",
      url: `users/${data.uuid}/parent-id`,
      data: { parent_uuid: data.parent_uuid },
    });
  },
  updatePermissions: (data: UserUpdatePermissionsInterface) =>
    request({ method: "put", url: `users/${data.uuid}/permissions-set`, data }),
  updateAnalysisLimit: (data: UserUpdateAnalysesLimitInterface) =>
    request({
      method: "put",
      url: `users/${data.uuid}/ai-analyses-limit`,
      data: {
        type: data.type,
        limit: data.limit,
      },
    }),
};

export default UsersService;
