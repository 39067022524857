import { useEffect, useState } from "react";
import { UserInterface, XRAY_TYPES, XrayTypesType } from "interfaces/user";
import { strSpaceCombine } from "utils/helpers";
import { ReactComponent as ForbiddenIcon } from "assets/icons/forbidden-icon.svg";
import { useUpdateAnalysisLimit } from "queries/users";
import { CommonModal } from "components/modals/common-modal";
import useBoolean from "hooks/useBoolean";
import { Button } from "components/buttons";

export const XRayQuotaBlock = ({ user, type }: { user: UserInterface; type: XrayTypesType }) => {
  const analysisLimit =
    user.ai_analyses_limit_data && user.ai_analyses_limit_data[type] !== undefined
      ? Number(user.ai_analyses_limit_data[type])
      : -1;
  const analysisExecuted =
    user.ai_analyses_executed && user.ai_analyses_executed[type] !== undefined
      ? Number(user.ai_analyses_executed[type])
      : 0;
  const isLimitExceeded = analysisLimit !== -1 && analysisExecuted >= analysisLimit;
  const isNoLimit = analysisLimit === -1;
  const isForbidden = analysisLimit === 0;
  const [limit, setLimit] = useState(analysisLimit);
  const { mutate: updateAnalysisLimit, isLoading, isSuccess } = useUpdateAnalysisLimit();
  const { value: isModalOpen, toggle: toggleIsModalOpen } = useBoolean(false);

  useEffect(() => {
    if (isSuccess) toggleIsModalOpen();
  }, [isSuccess, toggleIsModalOpen]);

  return (
    <>
      {!isForbidden ? (
        <button
          className="rounded-md bg-white/5 hover:bg-white/30 transition-colors p-1 text-center text-sm"
          type="button"
          onClick={toggleIsModalOpen}
        >
          <span className={strSpaceCombine([isLimitExceeded ? "text-red-500" : "text-white"])}>
            {analysisExecuted}
          </span>
          <span className="px-1">/</span>
          {isNoLimit ? "∞" : analysisLimit}
        </button>
      ) : (
        <button
          className="rounded-md bg-white/5 hover:bg-red-500/30 transition-colors p-1 text-center text-sm"
          type="button"
          onClick={toggleIsModalOpen}
        >
          <ForbiddenIcon className="fill-red-500" />
        </button>
      )}
      <CommonModal
        className="relative p-6 bg-[#222222] mx-auto border-0 rounded-xl max-w-fit max-h-fit"
        overlayClassName="absolute flex flex-col justify-center align-center left-0 right-0 top-0 bottom-0 bg-black/70"
        isOpen={isModalOpen}
        onRequestClose={toggleIsModalOpen}
      >
        <div className="flex flex-col">
          <span className="text-xl">{type} Analyses Limit</span>
          <div className="flex mt-6">
            <Button
              containerClassName={strSpaceCombine([
                "self-start rounded-r-none mt-0 bg-white/5 hover:!bg-red-400 focus:!ring-red-400/30",
                limit === 0 ? "!bg-red-500" : "",
              ])}
              title={`Disable ${type} Analyses`}
              onClick={() => setLimit(0)}
            >
              <ForbiddenIcon />
            </Button>
            <input
              className=" bg-white/5 px-3 h-12 outline-blue-500 hidden md:block"
              type="number"
              min={-1}
              max={999999}
              placeholder="e.g. 10"
              value={limit}
              onChange={(e) => setLimit(+e.target.value)}
            />
            <Button
              containerClassName={strSpaceCombine([
                "self-start rounded-l-none mt-0 bg-white/5 hover:!bg-green-400 focus:!ring-green-400/30",
                limit === -1 ? "!bg-green-500" : "",
              ])}
              title={`Remove limit for ${type} Analyses`}
              onClick={() => setLimit(-1)}
            >
              <span className="block w-6 h-6">∞</span>
            </Button>
          </div>
          <Button
            title={`Remove limit for ${type} Analyses`}
            onClick={() =>
              updateAnalysisLimit({ uuid: user.uuid ?? "", limit, type: XRAY_TYPES[type] })
            }
            label="Save"
            loading={isLoading}
          />
        </div>
      </CommonModal>
    </>
  );
};
