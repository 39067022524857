import { KeyboardEvent, MouseEvent, useEffect } from "react";
import { toast } from "react-toastify";
import useBoolean from "hooks/useBoolean";
import useDebounce from "hooks/useDebounce";
import { USER_PERMISSIONS, UserInterface, UserUpdateStatusInterface } from "interfaces/user";
import { useUpdateParent, useUsersUpdateStatus } from "queries/users";
import { getLocaleDateTime, strSpaceCombine } from "utils/helpers";
import { CommonModal } from "components/modals/common-modal";
import { LinkedAccountForm, RoleForm } from "components/forms";
import { Button } from "components/buttons";
import { XRayQuotaBlock } from "./x-ray-quota-block";

export const UsersRow = (
  data: UserInterface & {
    search: string;
    sortBy: string;
    order: string;
    page: number;
    perPage: string;
    index: number;
  }
) => {
  const { value: isAssociateOpen, toggle: toggleIsAssociateOpen } = useBoolean(false);
  const { value: isRoleOpen, toggle: toggleIsRoleOpen } = useBoolean(false);
  const { value: isRemoveAssociateOpen, toggle: toggleIsRemoveAssociateOpen } = useBoolean(false);
  const { value: visible, setTrue: setVisible } = useBoolean(false);
  const debouncedVisibility = useDebounce<Boolean>(visible, data.index * 15);

  const {
    mutate: updateParent,
    isLoading: isParentUpdating,
    isSuccess: isParentUpdated,
  } = useUpdateParent();
  const { mutate: updateStatus, isLoading } = useUsersUpdateStatus(
    data.search,
    data.sortBy,
    data.order,
    data.page,
    data.perPage
  );

  const handleUpdateStatus = () =>
    updateStatus({
      uuid: data.uuid,
      status: Boolean(data.status) ? 0 : 1,
    } as UserUpdateStatusInterface);

  const handleUpdateStatusViaKeyboard = (e: KeyboardEvent) =>
    e.code === "Enter" && handleUpdateStatus();

  const handleCopyEmailToClipboard = (e: MouseEvent<HTMLElement>) => {
    navigator.clipboard.writeText((e.target as HTMLElement).innerText);
    toast("Email copied!", { type: "success", autoClose: 1300 });
  };

  const handleCopyUUIDToClipboard = (uuid: string) => {
    navigator.clipboard.writeText(uuid);
    toast("User UUID copied!", { type: "success", autoClose: 1300 });
  };

  useEffect(() => setVisible(), [setVisible]);

  useEffect(() => {
    if (isParentUpdated) toggleIsRemoveAssociateOpen();
  }, [isParentUpdated, toggleIsRemoveAssociateOpen]);

  return (
    <tr
      className={strSpaceCombine([
        "group hover:bg-gradient-to-r from-white/5 via-white/5 bg-transparent transition-all duration-500",
        !debouncedVisibility ? "opacity-0" : "opacity-100",
      ])}
    >
      <td className="py-2 pl-2">
        <div className="flex flex-col">
          <span>{strSpaceCombine([data.first_name, data.last_name])}</span>
          <span
            className="text-xs cursor-copy text-white/50 hover:text-cyan-400 transition-colors font-semibold"
            title="Click to copy User UUID to clipboard"
            onClick={() => handleCopyUUIDToClipboard(data.uuid ?? "")}
          >
            {data.uuid}
          </span>
        </div>
      </td>
      <td>
        <span
          className="cursor-copy text-white/50 group-hover:text-white hover:text-blue-500 hover:ring-2 ring-white/20 bg-white/5 px-2 py-1 rounded-md font-bold text-xs transition-all"
          title="Click to copy Email to clipboard"
          onClick={handleCopyEmailToClipboard}
        >
          {data.email}
        </span>
      </td>
      <td className="text-white/50" title="CBCT Quota">
        <XRayQuotaBlock type="CBCT" user={data} />
      </td>
      <td className="text-white/50" title="CEPH Quota">
        <XRayQuotaBlock type="CEPH" user={data} />
      </td>
      <td className="text-white/50" title="OPG Quota">
        <XRayQuotaBlock type="OPG" user={data} />
      </td>
      <td className="text-white/50" title={data.created_at}>
        {getLocaleDateTime(data.created_at).date}
      </td>
      <td>
        <label
          tabIndex={0}
          onClick={handleUpdateStatus}
          onKeyUp={handleUpdateStatusViaKeyboard}
          className={strSpaceCombine([
            "relative cursor-pointer flex items-center w-10 h-6 rounded-full bg-white/5 hover:bg-white/20 transition-all",
            isLoading ? "animate-pulse outline-white/50" : "outline-blue-500",
          ])}
        >
          <span
            className={strSpaceCombine([
              "absolute block rounded-full transition-all",
              isLoading
                ? "w-8 h-4 bg-white/50 left-1"
                : Boolean(data.status)
                ? "w-4 h-4 bg-blue-500 left-5"
                : "w-4 h-4 bg-white/50 left-1",
            ])}
          ></span>
        </label>
      </td>
      <td>
        <button
          className="flex items-center bg-white/5 px-2 py-1 hover:bg-cyan-400/10 text-white/50  hover:text-cyan-400 transition-colors uppercase rounded-md overflow-clip font-semibold text-xs"
          onClick={toggleIsRoleOpen}
          title="Update User Role"
        >
          <span className="pr-1">+</span>
          <span>{USER_PERMISSIONS[data.permissions_set ?? "all"]}</span>
        </button>
        <CommonModal
          className="relative p-6 bg-[#222222] mx-auto border-0 rounded-xl max-w-fit max-h-fit"
          overlayClassName="absolute flex flex-col justify-center align-center left-0 right-0 top-0 bottom-0 bg-black/70"
          isOpen={isRoleOpen}
          onRequestClose={toggleIsRoleOpen}
        >
          <RoleForm user={data} onSuccess={toggleIsRoleOpen} />
        </CommonModal>
      </td>
      {data.parent?.email ? (
        <td>
          <button
            className="flex items-center bg-white/5 px-2 py-1 hover:bg-red-400/10 text-white/50  hover:text-red-400 transition-colors uppercase rounded-md overflow-clip font-semibold text-xs"
            onClick={toggleIsRemoveAssociateOpen}
            title="Update User Role"
          >
            <span className="pr-1">-</span>
            <span>{strSpaceCombine([data.parent.first_name, data.parent.last_name])}</span>
          </button>
          <CommonModal
            className="relative p-6 bg-[#222222] mx-auto border-0 rounded-xl max-w-fit max-h-fit"
            overlayClassName="absolute flex flex-col justify-center align-center left-0 right-0 top-0 bottom-0 bg-black/70"
            isOpen={isRemoveAssociateOpen}
            onRequestClose={toggleIsRemoveAssociateOpen}
          >
            <div className="flex flex-col">
              <span className="text-white text-xl font-semibold mb-2">Unlink Account</span>
              <span className="text-white/50 mb-4">
                Unlink{" "}
                <span className="font-semibold text-white">
                  {strSpaceCombine([data.first_name, data.last_name])}
                </span>{" "}
                from{" "}
                <span className="font-semibold text-white">
                  {strSpaceCombine([data?.parent.first_name, data?.parent.last_name])}
                </span>
                's account?
              </span>
              <Button
                label="Unlink"
                containerClassName="bg-red-500 hover:bg-red-400 focus:ring-red-400/30"
                loading={isParentUpdating}
                onClick={() => updateParent({ uuid: data.uuid ?? "", parent_uuid: null })}
              />
            </div>
          </CommonModal>
        </td>
      ) : (
        <td className="text-white/50">
          <button
            className="flex items-center bg-white/5 px-2 py-1 hover:bg-cyan-400/10  hover:text-cyan-400 transition-colors uppercase rounded-md overflow-clip font-semibold text-xs"
            onClick={toggleIsAssociateOpen}
            title={`Give ${
              data.first_name + " " + data.last_name
            }' acc access to cases from another account`}
          >
            +
          </button>
          <CommonModal
            className="relative p-6 bg-[#222222] mx-auto border-0 rounded-xl max-w-fit max-h-fit"
            overlayClassName="absolute flex flex-col justify-center align-center left-0 right-0 top-0 bottom-0 bg-black/70"
            isOpen={isAssociateOpen}
            onRequestClose={toggleIsAssociateOpen}
          >
            <LinkedAccountForm user={data} onSuccess={toggleIsAssociateOpen} />
          </CommonModal>
        </td>
      )}
    </tr>
  );
};
