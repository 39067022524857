import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "components/buttons";
import { useUpdateUserPermissions } from "queries/users";
import { UserInterface, UserUpdatePermissionsInterface } from "interfaces/user";
import { strSpaceCombine } from "utils/helpers";

export const RoleForm = ({ user, onSuccess }: { user?: UserInterface; onSuccess: Function }) => {
  const { mutate: updatePermissions, isLoading, isSuccess } = useUpdateUserPermissions();

  const handleSubmit = (data: UserUpdatePermissionsInterface) =>
    updatePermissions({ ...data, uuid: user?.uuid ?? "" });

  useEffect(() => {
    if (isSuccess) onSuccess();
  }, [isSuccess, onSuccess]);

  return (
    <Formik
      initialValues={{ uuid: user?.uuid ?? "", value: user?.permissions_set }}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleBlur, handleChange }) => (
        <Form className="flex flex-col w-full">
          <span className="text-xl font-semibold mb-6">
            {strSpaceCombine([user?.first_name, user?.last_name])}'s Role
          </span>
          <div className="flex flex-col mb-2">
            <Field
              as="select"
              name="value"
              className=" bg-white/5 px-3 h-12 rounded-md outline-blue-500 hidden md:block"
            >
              <option value="all" disabled={user?.parent ? true : false}>
                Unrestricted
              </option>
              <option value="view_only">Viewer</option>
              <option value="all_except_ai">Manager (No AI)</option>
            </Field>
            {user?.parent && (
              <div className="text-white/50 text-xs mt-4">
                <span className="font-semibold text-white">Unrestricted</span> role can only be set
                when there is no linked accounts for the current user.
              </div>
            )}
          </div>
          <Button type="submit" label="Update Role" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};
