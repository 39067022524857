import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import {
  UserInterface,
  UserUpdateAnalysesLimitInterface,
  UserUpdateParentInterface,
  UserUpdatePermissionsInterface,
  UserUpdateStatusInterface,
  XRAY_TYPES,
} from "interfaces/user";
import { toast } from "react-toastify";
import UsersService from "services/users";

export const USERS_QK = "users";

export const useUsers = (
  keyword?: string,
  sortBy?: string,
  order?: string,
  page?: number,
  perPage?: string
) =>
  useQuery(
    [USERS_QK, keyword, sortBy, order, page, perPage],
    () => UsersService.get(keyword, sortBy, order, page, perPage),
    { keepPreviousData: true }
  );

export const useUpdateParent = () =>
  useMutation([], (data: UserUpdateParentInterface) => UsersService.updateParent(data), {
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [USERS_QK], stale: true });
      toast("Account linked successfully", { toastId: "account-linked", type: "success" });
    },
  });

export const useUpdateUserPermissions = () =>
  useMutation([], (data: UserUpdatePermissionsInterface) => UsersService.updatePermissions(data), {
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [USERS_QK], stale: true });
      toast("User role updated successfully", { toastId: "role-updated", type: "success" });
    },
  });

export const useUsersUpdateStatus = (
  keyword?: string,
  sortBy?: string,
  order?: string,
  page?: number,
  perPage?: string
) =>
  useMutation(
    [USERS_QK, keyword, sortBy, order, page, perPage],
    (data: UserUpdateStatusInterface) => UsersService.updateStatus(data),
    {
      onSuccess: (updatedData, variables) => {
        queryClient.setQueryData(
          [USERS_QK, keyword, sortBy, order, page, perPage],
          (oldData: any) => {
            const links: any = oldData.data.links;
            const meta: any = oldData.data.meta;
            const users: Array<UserInterface> = oldData.data.data;
            const updatedUser: UserInterface = updatedData.data;

            return {
              ...oldData,
              data: {
                links,
                meta,
                data: users.map((user: UserInterface) =>
                  user.uuid === variables.uuid ? { ...user, status: updatedUser.status } : user
                ),
              },
            };
          }
        );
      },
    }
  );

export const useUpdateAnalysisLimit = () =>
  useMutation(
    [],
    (data: UserUpdateAnalysesLimitInterface) => UsersService.updateAnalysisLimit(data),
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries({ queryKey: [USERS_QK], stale: true });
        toast(
          `${XRAY_TYPES[variables.type]} limit set to ${
            variables.limit === -1 ? "∞" : variables.limit
          }`,
          { type: "success" }
        );
      },
    }
  );
