import { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "components/buttons";
import { Input } from "components/inputs";
import { useUpdateParent } from "queries/users";
import { UserInterface, UserUpdateParentInterface } from "interfaces/user";
import { strSpaceCombine } from "utils/helpers";

export const LinkedAccountForm = ({
  user,
  onSuccess,
}: {
  user?: UserInterface;
  onSuccess: Function;
}) => {
  const { mutate: updateParent, isLoading, isSuccess } = useUpdateParent();

  const handleSubmit = (data: UserUpdateParentInterface) =>
    updateParent({ ...data, uuid: user?.uuid ?? "" });

  useEffect(() => {
    if (isSuccess) onSuccess();
  }, [isSuccess, onSuccess]);

  return (
    <Formik
      initialValues={{ uuid: user?.uuid ?? "", parent_uuid: "" }}
      validationSchema={LinkedAccountSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleBlur, handleChange }) => (
        <Form className="flex flex-col w-full">
          <span className="text-xl font-semibold mb-6">
            Link {strSpaceCombine([user?.first_name, user?.last_name])}'s Account
          </span>
          <div className="flex flex-col mb-2">
            <span className="uppercase font-bold text-xs mb-2">Parent</span>
            <Input
              type="text"
              name="parent_uuid"
              placeholder="User UUID"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched}
              errors={errors}
              disabled={isLoading}
              autoFocus
            />
          </div>
          <Button type="submit" label="Link Account" loading={isLoading} />
          <div className="text-xs text-white/50 mt-4">
            By linking{" "}
            <span className="font-semibold">
              {strSpaceCombine([user?.first_name, user?.last_name])}
            </span>
            's account to a parent account, you're giving{" "}
            <span className="font-semibold">
              {strSpaceCombine([user?.first_name, user?.last_name])}
            </span>{" "}
            permission to access parent's cases. Parent's UUID can be found under user name in Users
            table.
          </div>
        </Form>
      )}
    </Formik>
  );
};

const LinkedAccountSchema = Yup.object().shape({
  parent_uuid: Yup.string().required("Required"),
});
